<template>
  <RightSideBar
    v-if="openModal"
    submit="Apply"
    @submit="save"
    @close="$emit('close')"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Add Exception
      </p>
    </template>
    <p class="text-darkPurple">
      Employees may be confirmed at any time with good reasons
    </p>
    <div>
      <div class=" w-full mt-8 flex font-semibold">
        Minimum probation period
        <c-select
          placeholder="--Select--"
          :options="numbers"
          style="margin: -13px 24px 0 24px"
          v-model="months"
        />
        months
      </div>
      <alert
        message="Use Roles and Privileges to define who can initiate “exceptional” confirmation."
        variant="primary"
        :time="2"
        style="position: relative; right: 0; top: 20px;
             border: 1px solid #E99323; box-shadow:none; background: rgba(244, 213, 176, 0.15);; color: #E99323;"
      />
    </div>
  </RightSideBar>
</template>

<script>
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import RightSideBar from "@/components/RightSideBar";

export default {
  name: "Exception",
  components: {
    RightSideBar,
    CSelect,
    Alert
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    exception: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      months: null,
      numbers: [
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
        { id: 6, name: 6 },
        { id: 7, name: 7 },
        { id: 8, name: 8 },
        { id: 9, name: 9 },
        { id: 10, name: 10 },
        { id: 11, name: 11 },
        { id: 12, name: 12 }
      ]
    };
  },
  watch: {
    openModal() {
      if (this.openModal) {
        this.months = this.exception;
      }
    }
  },
  methods: {
    async save() {
      this.$emit("addException", this.months);
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss"></style>
